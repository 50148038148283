import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import emailjs, { EmailJSResponseStatus } from 'emailjs-com';

@Component({
  selector: 'app-reg-form',
  templateUrl: './reg-form.component.html',
  styleUrls: ['./reg-form.component.css']
})
export class RegFormComponent implements OnInit {

  btn: HTMLInputElement;

  first: string;
  last: string;
  email: string;
  phone: string;

  FormData: FormGroup;

  public sendEmail(e: Event) {

    this.btn = <HTMLInputElement> document.getElementById("submit");
    this.btn.innerText = "Sending...";
    
    emailjs.sendForm('service_vpsj018', 'template_u4x8wgc', e.target as HTMLFormElement, 'user_F3kPzd8MxQ0AotsmmysW9')
      .then((result: EmailJSResponseStatus) => {
        this.btn.innerText = "Sent!";
        this.btn.disabled = true;
        this.btn.style.backgroundColor = "#3a5";
        console.log(result.text);
      }, (error) => {
        alert(error.text);
        console.log(error.text);
      });
  }

  constructor(private builder: FormBuilder) {
  }

  ngOnInit(): void {

    this.FormData = this.builder.group({
      First: new FormControl('', [Validators.required]),
      Last: new FormControl('', [Validators.required]),
      Email: new FormControl('', [Validators.compose([Validators.required, Validators.email])]),
      Phone: new FormControl('', Validators.compose([Validators.required, Validators.minLength(10)]))
    });
  }

}
