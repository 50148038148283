<div class="container vh-100">
  <div class="row min-vh-100 align-items-center">
    <div class="row justify-content-center">
      <div class="col-10 justify-content-center">
        <a href="https://rickmooregroup.com" target="_BLANK">
          <img
            src="../assets/rmg-white.png"
            alt="Rick Moore Group | Keller Williams Western Realty"
            class="img-fluid"
          />
        </a>
      </div>
      <div
        class="
          card
          border border-light border-5
          shadow-lg
          col-5 col-lg-10
          justify-content-center
          p-3
        "
      >
        <div class="row align-items-center">
          <div class="col-lg-4 pb-2 align-items-center">
            <img src="../assets/event.png" class="img-fluid rounded shadow" />
          </div>
          <div class="col-lg-4">
            <h2 class="text-center">Dec. 15th | 6-9PM</h2>
            <h3 class="text-center">Buffalo Wild Wings</h3>
            <form [formGroup]="FormData" (ngSubmit)="sendEmail($event)">
              <div class="form-group py-1">
                <!-- <label for="First">First Name</label> -->
                <input
                  type="text"
                  placeholder="First Name"
                  name="First"
                  class="form-control"
                  formControlName="First"
                />
              </div>
              <div class="form-group py-1">
                <!-- <label for="Last">Last Name</label> -->
                <input
                  type="text"
                  placeholder="Last Name"
                  name="Last"
                  class="form-control"
                  formControlName="Last"
                />
              </div>
              <div class="form-group py-1">
                <!-- <label for="Email">Email</label> -->
                <input
                  type="email"
                  placeholder="Email Address"
                  name="Email"
                  class="form-control"
                  formControlName="Email"
                />
              </div>
              <div class="form-group py-1">
                <!-- <label for="Phone">Phone</label> -->
                <input
                  type="text"
                  placeholder="Phone Number"
                  name="Phone"
                  class="form-control"
                  formControlName="Phone"
                />
              </div>
              <div class="d-grid gap-2 mx-auto pt-1">
                <button
                  id="submit"
                  class="btn btn-primary"
                  type="submit"
                  [disabled]="!FormData.valid"
                >
                  Sign Up
                </button>
              </div>
            </form>
          </div>
          <div class="col-lg-4 pt-2">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2628.829374345748!2d-122.49201608408121!3d48.78514761381453!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5485a35d7cfc3fc1%3A0x50fbea05cb3a8d69!2sBuffalo%20Wild%20Wings!5e0!3m2!1sen!2sus!4v1629066575370!5m2!1sen!2sus"
              width="100%"
              height="330"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
